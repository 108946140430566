/* config */
a {
  text-decoration: none;
  cursor: pointer; }

p a {
  font-weight: var(--font-w-bold);
  color: var(--color-blue-link); }
  p a:hover {
    text-decoration: underline; }

button {
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
  border: 0;
  background: transparent;
  appearance: none;
  cursor: pointer;
  outline: none; }

figure {
  margin: 0; }

ul {
  margin: 0;
  padding: 0; }

@font-face {
  font-family: 'butlerlight';
  src: url(..//fonts/butler_light-webfont.woff2) format("woff2"), url(..//fonts/butler_light-webfont.woff) format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'butlermedium';
  src: url(..//fonts/butler_medium-webfont.woff2) format("woff2"), url(..//fonts/butler_medium-webfont.woff) format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'butlerregular';
  src: url(..//fonts/butler_regular-webfont.woff2) format("woff2"), url(..//fonts/butler_regular-webfont.woff) format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'montserrat';
  src: url(..//fonts/montserrat-light-webfont.woff2) format("woff2"), url(..//fonts/montserrat-light-webfont.woff) format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'montserrat';
  src: url(..//fonts/montserrat-medium-webfont.woff2) format("woff2"), url(..//fonts/montserrat-medium-webfont.woff) format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'montserrat';
  src: url(..//fonts/montserrat-regular-webfont.woff2) format("woff2"), url(..//fonts/montserrat-regular-webfont.woff) format("woff");
  font-weight: 400;
  font-style: normal; }

:root {
  /* colors */
  --color-blue: #58778b;
  --color-grey: #f4f2f3;
  --color-lgrey:#fafafa;
  --color-black: #000;
  --color-white: #fff;
  --color-blue-link: #57bcb7;
  /* font */
  --font-f-base: 'montserrat';
  --font-f-heading: 'butlerregular';
  --font-f-butler-m: 'butlermedium';
  --font-s-base: 16px;
  --font-s-nav: 14px;
  --font-s-heading-xl: 11.458vw;
  --font-s-heading-l: 75px;
  --font-s-heading-lg: 2.865vw;
  --font-s-heading-md: 2.083vw;
  --font-s-heading-sm: 1.406vw;
  --fon-s-heading-s: 18px;
  --font-s-quote: 1.771vw;
  --font-s-button: 12px;
  --font-s-i: 200px;
  --font-s-i-l: 400px;
  --font-s-i-xl: 30vw;
  --font-s-banner: 220px;
  --font-s-news: 45px;
  --lh-base: 25px;
  --lh-nav: 1.59vw;
  --lh-tag: 20px;
  --lh-heading-lg: 12vw;
  --lh-heading-md: 9vw;
  --lh-heading-sm: 28px;
  --lh-button: 48px;
  --font-w-light: 300;
  --font-w-regular: 400;
  --font-w-medium: 500;
  --font-w-bold: 700;
  --font-w-black: 900;
  /* spacings */
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-15: 15px;
  --spacing-30: 30px;
  --spacing-45: 45px;
  --spacing-60: 60px;
  --spacing-80: 80px;
  --spacing-90: 90px;
  --spacing-100: 100px;
  --spacing-120: 120px;
  --spacing-150: 150px;
  --spacing-180: 180px;
  --spacing-220: 220px;
  --spacing-240: 240px;
  /* easings */
  --ease-expo-in: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --ease-expo-out: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-expo-in-out: cubic-bezier(1, 0, 0, 1);
  --ease-power3-in: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --ease-power3-out: cubic-bezier(0.165, 0.84, 0.44, 1);
  --ease-power3-in-out: cubic-bezier(0.77, 0, 0.175, 1);
  --ease: ease;
  /* grid */
  --gutter: calc((100% / 12 * 1) / 4);
  --outer-gutter: 20px;
  --grid-1: calc(100% / 12 * 1);
  --grid-2: calc(100% / 12 * 2);
  --grid-3: calc(100% / 12 * 3);
  --grid-4: calc(100% / 12 * 4);
  --grid-5: calc(100% / 12 * 5);
  --grid-6: calc(100% / 12 * 6);
  --grid-7: calc(100% / 12 * 7);
  --grid-8: calc(100% / 12 * 8);
  --grid-9: calc(100% / 12 * 9);
  --grid-10: calc(100% / 12 * 10);
  --grid-11: calc(100% / 12 * 11);
  --grid-12: calc(100% / 12 * 12); }

@media only screen and (max-width: 1179px) {
  :root {
    --font-s-heading-lg: 4.665vw;
    --font-s-heading-md: 3.393vw;
    --font-s-heading-sm: 2.290vw;
    --font-s-heading-xl: 18.644vw;
    --font-s-quote: 2.881vw
  ; }
  .brands .brands__con h2 {
    --font-s-heading-lg: 3.6vw; } }

@media only screen and (max-width: 1024px) {
  :root {
    --font-s-heading-lg: 5.544vw;
    --font-s-heading-md: 4.032vw;
    --font-s-heading-sm: 2.722vw;
    --font-s-heading-xl: 21.484vw;
    --font-s-quote: 3.320vw; }
  .brands .brands__con h2 {
    --font-s-heading-lg: 5.544vw; } }

@media only screen and (max-width: 768px) {
  :root {
    --font-s-heading-lg: 7.161vw;
    --font-s-heading-md: 5.208vw;
    --font-s-heading-sm: 3.516vw;
    --font-s-heading-xl: 28.646vw;
    --font-s-quote: 4.427vw
  ; }
  .brands .brands__con h2 {
    --font-s-heading-lg: 7.161vw; } }

@media only screen and (max-width: 479px) {
  :root {
    --font-s-heading-lg: 11.458vw;
    --font-s-heading-md: 8.333vw;
    --font-s-heading-sm: 5.625vw;
    --font-s-heading-xl: 45.833vw;
    --font-s-quote: 7.083vw; }
  .brands .brands__con h2 {
    --font-s-heading-lg: 11.458vw; } }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll; }

body {
  position: relative;
  margin: 0;
  font-family: var(--font-f-base);
  font-size: var(--font-s-base);
  line-height: var(--lh-base);
  text-size-adjust: 100%; }

* {
  box-sizing: border-box; }

.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--spacing-60);
  position: relative; }
  @media only screen and (max-width: 479px) {
    .container {
      padding: 0 var(--spacing-30); } }
  @media only screen and (max-width: 1024px) {
    .container {
      overflow-x: hidden; } }

h1 {
  color: var(--color-black);
  font-family: var(--font-f-heading);
  font-size: var(--font-s-heading-lg);
  line-height: 110%;
  font-weight: var(--font-w-regular);
  margin: 0 0 60px 0; }
  h1.title__xl {
    font-size: var(--font-s-heading-xl);
    line-height: 0; }
  @media only screen and (max-width: 479px) {
    h1 {
      margin-bottom: 30px; } }

h2 {
  font-family: var(--font-f-heading);
  font-size: var(--font-s-heading-md);
  font-weight: var(--font-w-regular);
  line-height: 110%;
  margin: 0 0 60px 0; }
  h2.title {
    font-size: var(--font-s-heading-lg); }

h3 {
  font-family: var(--font-f-heading);
  font-weight: var(--font-w-regular);
  font-size: var(--font-s-nav);
  letter-spacing: var(--spacing-4);
  margin: 0 0 15px 0;
  text-transform: uppercase; }

p {
  font-size: var(--font-s-base);
  line-height: 175%;
  margin: 0 0 30px;
  font-weight: var(--font-w-light); }

/* components */
.banner {
  height: 450px;
  overflow: hidden;
  position: relative; }
  .banner.banner--dark {
    background-color: var(--color-black); }
    .banner.banner--dark .banner__text {
      background: -webkit-linear-gradient(#353535, #000);
      -webkit-background-clip: text; }
    .banner.banner--dark .banner__logo svg {
      fill: var(--color-white); }
  .banner .banner__text {
    -webkit-text-fill-color: transparent;
    background: -webkit-linear-gradient(#f6f6f6, #ffffff);
    -webkit-background-clip: text;
    font-size: var(--font-s-banner);
    font-family: var(--font-f-heading);
    text-align: center;
    letter-spacing: 7vw;
    text-transform: uppercase;
    line-height: 1.3;
    height: 100%;
    position: relative;
    left: 6%; }
  .banner .banner__logo {
    width: 220px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute; }
    .banner .banner__logo svg {
      fill: var(--color-black); }

[data-animate="brands"] .brands_logo__logo {
  will-change: transform;
  backface-visibility: hidden; }

.brands {
  height: calc(100vh - 205px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px; }
  @media only screen and (max-width: 1024px) {
    .brands {
      padding: 0 var(--spacing-30);
      flex-flow: row;
      flex-wrap: wrap; } }
  @media only screen and (max-width: 479px) {
    .brands {
      height: unset;
      padding: var(--spacing-60) var(--spacing-30);
      flex-flow: row;
      flex-wrap: wrap; } }
  .brands .brands__con {
    text-align: center;
    position: relative;
    flex: 0 1 25%;
    padding: 0 30px;
    min-height: 310px;
    display: flex;
    flex-flow: column; }
    @media only screen and (max-width: 1024px) {
      .brands .brands__con {
        padding: 0;
        min-height: unset;
        flex: 0 0 calc(50% - 30px); } }
    @media only screen and (max-width: 479px) {
      .brands .brands__con {
        flex: 0 0 100%; } }
    .brands .brands__con h2 {
      font-size: var(--font-s-heading-lg);
      margin-bottom: 30px;
      width: 100%;
      text-align: center;
      min-height: 120px;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media only screen and (max-width: 1024px) {
        .brands .brands__con h2 {
          font-size: 4vw;
          min-height: unset; } }
      @media only screen and (max-width: 479px) {
        .brands .brands__con h2 {
          font-size: var(--font-s-heading-lg); } }
    .brands .brands__con i {
      position: absolute;
      left: 0;
      top: 50%;
      opacity: 0;
      width: 100%;
      height: 100%;
      line-height: 0;
      font-size: var(--font-s-i-xl);
      font-style: normal;
      font-family: var(--font-f-heading);
      transition: all 200ms ease; }
    .brands .brands__con:hover i {
      opacity: .025; }
    .brands .brands__con p {
      margin: auto auto 0;
      min-height: 80px;
      display: flex;
      align-items: flex-start; }
      @media only screen and (max-width: 1024px) {
        .brands .brands__con p {
          margin: 0 0 30px 0; } }
      @media only screen and (max-width: 479px) {
        .brands .brands__con p {
          display: unset;
          min-height: unset; } }
    @media only screen and (max-width: 1024px) {
      .brands .brands__con:nth-child(2) {
        margin: 0 var(--spacing-30); } }
    @media only screen and (max-width: 479px) {
      .brands .brands__con:nth-child(2) {
        margin: var(--spacing-60) 0; } }
    @media only screen and (max-width: 1024px) {
      .brands .brands__con:nth-child(3) {
        margin: 0 var(--spacing-30); } }
    @media only screen and (max-width: 479px) {
      .brands .brands__con:nth-child(3) {
        margin-bottom: var(--spacing-60); } }
    .brands .brands__con .button {
      margin: 30px auto 0; }
      @media only screen and (max-width: 1024px) {
        .brands .brands__con .button {
          margin: auto auto 0; } }

.brands_logo {
  margin: var(--spacing-60) 0 var(--spacing-120); }
  .brands_logo .container {
    padding: 0 var(--spacing-220); }
    @media only screen and (max-width: 479px) {
      .brands_logo .container {
        padding: 0 var(--spacing-30p); } }
  .brands_logo .brands_logo__con {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }
    .brands_logo .brands_logo__con .brands_logo__logo {
      height: 120px;
      position: relative;
      margin: 30px;
      flex: 0 1 calc(20% - 60px); }
      @media only screen and (max-width: 1024px) {
        .brands_logo .brands_logo__con .brands_logo__logo {
          flex: 0 1 calc(33% - 60px); } }
      @media only screen and (max-width: 479px) {
        .brands_logo .brands_logo__con .brands_logo__logo {
          flex: 0 1 100%; } }
      .brands_logo .brands_logo__con .brands_logo__logo .image__source {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center; }

.wpcf7 label {
  font-weight: var(--font-w-bold); }
  .wpcf7 label span {
    width: 100%; }
    .wpcf7 label span input, .wpcf7 label span textarea {
      width: 100%;
      margin-top: 5px;
      padding: 10px; }
      .wpcf7 label span input.wpcf7-not-valid, .wpcf7 label span textarea.wpcf7-not-valid {
        border: 1px solid red; }
    .wpcf7 label span .wpcf7-not-valid-tip {
      color: red;
      margin-top: 5px;
      display: block; }

footer {
  background: var(--color-black);
  color: var(--color-white);
  line-height: 115px;
  padding: 0 var(--spacing-60);
  position: relative;
  display: flex;
  justify-content: space-between; }
  footer a {
    color: white; }
    footer a:hover {
      text-decoration: underline; }
  @media only screen and (max-width: 479px) {
    footer {
      padding: 15px 30px;
      line-height: unset; } }

.grid {
  height: 100vh;
  display: grid;
  grid-template-areas: 'large large medium medium medium medium' 'large large smallFirst smallFirst smallSecond smallSecond'; }
  @media only screen and (max-width: 1024px) {
    .grid {
      grid-template-areas: 'large medium' 'smallFirst smallSecond'; } }
  @media only screen and (max-width: 479px) {
    .grid {
      grid-template-areas: 'large' 'medium' 'smallFirst' 'smallSecond';
      height: unset; } }
  @media only screen and (max-width: 1179px) {
    .grid {
      height: 100%; } }
  .grid .grid__col {
    padding: var(--spacing-60);
    position: relative;
    height: 50vh; }
    @media only screen and (max-width: 1179px) {
      .grid .grid__col {
        height: 100%; } }
    @media only screen and (max-width: 768px) {
      .grid .grid__col {
        padding: var(--spacing-60) 30px; } }
    .grid .grid__col h3 {
      text-transform: uppercase;
      font-size: var(--fon-s-heading-s); }
    .grid .grid__col .grid__content {
      z-index: 3;
      position: relative;
      display: flex;
      align-items: flex-start;
      flex-flow: column; }
      .grid .grid__col .grid__content .button {
        margin-top: auto; }
      @media only screen and (max-width: 479px) {
        .grid .grid__col .grid__content .grid__title {
          margin: 0 0 var(--spacing-30) 0; } }
    .grid .grid__col .grid__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      overflow: hidden; }
      .grid .grid__col .grid__image .image__source {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; }
    .grid .grid__col .grid__day {
      z-index: 2;
      top: 20%;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: .025;
      font-size: var(--font-s-i);
      text-align: center;
      font-weight: var(--font-w-regular);
      font-family: var(--font-f-heading); }
    @media only screen and (max-width: 479px) {
      .grid .grid__col {
        padding: var(--spacing-120) var(--spacing-30) var(--spacing-30); } }
  .grid .grid__large {
    grid-area: large;
    background-color: var(--color-blue);
    color: var(--color-white);
    height: 100vh; }
    @media only screen and (max-width: 1179px) {
      .grid .grid__large {
        height: 100%; } }
    .grid .grid__large .grid__content {
      height: calc(50% - 60px); }
    .grid .grid__large .grid__image {
      top: unset;
      bottom: 0;
      height: 50%; }
      @media only screen and (max-width: 479px) {
        .grid .grid__large .grid__image {
          height: 100%; } }
  .grid .grid__medium {
    grid-area: medium;
    background-color: var(--color-grey); }
    .grid .grid__medium .grid__content {
      width: 50%;
      height: 100%; }
      @media only screen and (max-width: 1179px) {
        .grid .grid__medium .grid__content {
          width: 100%; } }
  .grid .grid__small {
    background: var(--color-white); }
    .grid .grid__small .grid__content {
      height: 100%; }
      .grid .grid__small .grid__content .grid__title {
        font-size: var(--font-s-heading-sm);
        line-height: 140%; }
  .grid .grid__small--first {
    grid-area: smallFirst; }
  .grid .grid__small--second {
    grid-area: smallSecond;
    background: var(--color-black); }
    .grid .grid__small--second .grid__content {
      color: var(--color-white); }

.grid__col--light .grid__content {
  color: var(--color-white); }

.single .header {
  min-height: 500px;
  width: 100%;
  position: relative; }
  .single .header .container {
    max-width: 860px;
    height: 500px; }
  .single .header .header__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--color-black); }
    .single .header .header__image .image__source {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
    .single .header .header__image::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 29%, rgba(0, 0, 0, 0.5) 100%); }
  .single .header .header__content {
    position: absolute;
    left: 0;
    bottom: 60px;
    z-index: 2; }
    @media only screen and (max-width: 768px) {
      .single .header .header__content {
        left: unset; } }
    .single .header .header__content .header__date {
      color: var(--color-white); }
    .single .header .header__content .header__title h1 {
      color: var(--color-white);
      margin-bottom: 0; }
    @media only screen and (max-width: 768px) {
      .single .header .header__content {
        padding: 0 30px; } }

.header.header__home {
  display: flex;
  height: 80vh; }
  .header.header__home .header__col {
    padding: var(--spacing-60);
    position: relative;
    width: 100%;
    overflow: hidden; }
    .header.header__home .header__col .header__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .header.header__home .header__col .header__image::before {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1; }
      .header.header__home .header__col .header__image .image__source {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; }
    .header.header__home .header__col .header__content {
      position: absolute;
      bottom: 60px;
      left: 60px;
      z-index: 2;
      max-width: 360px; }
      @media only screen and (max-width: 768px) {
        .header.header__home .header__col .header__content {
          left: 30px; } }
    .header.header__home .header__col.header--light .header__image::before {
      background: linear-gradient(to top right, #ffffff 0%, #ffffff 24%, rgba(255, 255, 255, 0.5) 49%, rgba(255, 255, 255, 0) 76%, rgba(255, 255, 255, 0) 100%); }
    .header.header__home .header__col.header--dark .header__image {
      background: var(--color-black); }
    .header.header__home .header__col.header--dark .header__content .header__title, .header.header__home .header__col.header--dark .header__content p {
      color: var(--color-white); }

@media only screen and (max-width: 479px) {
  .header.header__home {
    display: block;
    height: 100%; }
    .header.header__home .header__col {
      flex: 0 0 100%;
      padding: var(--spacing-30); }
      .header.header__home .header__col .header__content {
        bottom: 0px;
        left: 0px;
        position: relative; }
        .header.header__home .header__col .header__content p {
          opacity: .8; } }

.image {
  position: relative;
  height: 540px;
  max-width: 840px;
  margin: 0 auto;
  margin: 60px auto; }
  .image .image__file {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 10px; }
    .image .image__file .image__source {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
  .image .image__caption {
    font-family: var(--font-f-base); }
  @media only screen and (max-width: 1024px) {
    .image {
      padding: 0 30px; } }

[data-animate="text"] .image__source {
  opacity: 0;
  transform: scale(1.1);
  will-change: transform;
  backface-visibility: hidden; }

.latest-news {
  margin: var(--spacing-100) 0 0;
  padding: var(--spacing-100) 0;
  background: var(--color-lgrey); }
  .latest-news .container {
    padding: 0 8.33%; }
  .latest-news .news__col {
    margin-bottom: var(--spacing-60);
    position: relative;
    overflow: hidden; }
    .latest-news .news__col .news__image {
      width: 20%;
      height: 100%;
      position: absolute;
      left: 0; }
      @media only screen and (min-width: 768px) {
        .latest-news .news__col .news__image {
          width: 40%; } }
      .latest-news .news__col .news__image .image__source {
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; }
    .latest-news .news__col .news__text {
      width: 50%;
      padding: var(--spacing-30) 0;
      left: 30%;
      position: relative; }
      @media only screen and (min-width: 768px) {
        .latest-news .news__col .news__text {
          float: right;
          left: unset; } }
      .latest-news .news__col .news__text h3 {
        font-family: var(--font-f-butler-m);
        font-size: var(--font-s-nav); }
      .latest-news .news__col .news__text h2 {
        font-size: var(--font-s-heading-sm);
        width: 60%;
        padding-right: var(--spacing-30);
        margin-bottom: 0; }
        @media only screen and (min-width: 768px) {
          .latest-news .news__col .news__text h2 {
            width: 100%;
            margin-bottom: 30px; } }
      .latest-news .news__col .news__text .button {
        position: absolute;
        right: 0;
        bottom: 30px;
        z-index: 1; }
        @media only screen and (min-width: 768px) {
          .latest-news .news__col .news__text .button {
            position: relative;
            bottom: 0; } }
      .latest-news .news__col .news__text i {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 100%;
        opacity: .025;
        line-height: 0;
        font-style: normal;
        font-size: var(--font-s-i);
        font-family: var(--font-f-heading); }
    @media only screen and (min-width: 992px) {
      .latest-news .news__col .news__image {
        width: 20%; }
      .latest-news .news__col .news__text {
        float: unset;
        left: 30%; }
        .latest-news .news__col .news__text h2 {
          width: 60%;
          margin: 0; }
        .latest-news .news__col .news__text .button {
          position: absolute;
          right: 0;
          bottom: 30px;
          z-index: 1; } }
    @media only screen and (max-width: 479px) {
      .latest-news .news__col .news__image {
        position: relative;
        width: 100%;
        min-height: 300px; }
      .latest-news .news__col .news__text {
        width: 100%;
        left: unset;
        padding-bottom: 0; }
        .latest-news .news__col .news__text h2 {
          width: 100%;
          margin-bottom: var(--spacing-30); }
        .latest-news .news__col .news__text .button {
          position: relative;
          bottom: 0; } }
    .latest-news .news__col.last-child {
      margin-bottom: 0; }

nav {
  background-color: var(--color-black);
  height: 90px;
  position: relative;
  z-index: 11; }
  nav .nav__logo {
    max-width: 130px;
    height: 43px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: var(--color-white); }
  nav .nav__button {
    position: absolute;
    right: 30px;
    color: white;
    line-height: 90px;
    display: none;
    cursor: pointer; }
  nav .nav__list {
    display: flex;
    line-height: 90px; }
    nav .nav__list li {
      flex-basis: 20%;
      text-align: center; }
      nav .nav__list li.current-menu-item a {
        border-bottom: 1px solid white; }
      nav .nav__list li:nth-child(2) {
        margin-right: 10%; }
      nav .nav__list li:nth-child(3) {
        margin-left: 10%; }
      nav .nav__list li a {
        color: var(--color-white);
        font-size: var(--font-s-nav);
        text-transform: uppercase;
        font-family: var(--font-f-base);
        letter-spacing: var(--spacing-4);
        margin-right: -4px; }
        nav .nav__list li a:hover {
          border-bottom: 1px solid #fff; }
  @media only screen and (max-width: 479px) {
    nav .nav__logo {
      left: 30px;
      transform: translateY(-50%); }
    nav .nav__menu {
      display: none; }
    nav .nav__button {
      display: block; } }

.nav__mobile {
  width: 100%;
  background: var(--color-black);
  position: absolute;
  right: 0;
  top: var(--spacing-90);
  z-index: 10;
  padding: var(--spacing-60);
  display: none;
  transform: translateY(-100%);
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
  .nav__mobile.nav__mobile--active {
    transform: translateY(0%); }
  .nav__mobile .nav__mobile__list li a {
    color: var(--color-white);
    font-size: var(--font-s-nav);
    text-transform: uppercase; }
  @media only screen and (max-width: 479px) {
    .nav__mobile {
      display: block; } }

.news {
  margin: var(--spacing-100) 0; }
  @media only screen and (max-width: 479px) {
    .news {
      margin: var(--spacing-60) 0; } }
  .news .container {
    padding: 0 8.33%; }
  .news .news__col {
    margin-bottom: var(--spacing-60);
    position: relative;
    overflow: hidden; }
    .news .news__col .news__image {
      width: 20%;
      height: 100%;
      position: absolute;
      left: 0; }
      @media only screen and (min-width: 768px) {
        .news .news__col .news__image {
          width: 40%; } }
      .news .news__col .news__image .image__source {
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; }
    .news .news__col .news__text {
      width: 50%;
      padding: var(--spacing-30) 0;
      left: 30%;
      position: relative; }
      @media only screen and (min-width: 768px) {
        .news .news__col .news__text {
          float: right;
          left: unset; } }
      .news .news__col .news__text h3 {
        font-family: var(--font-f-butler-m);
        font-size: var(--fon-s-heading-s); }
      .news .news__col .news__text h2 {
        font-size: var(--font-s-heading-sm);
        width: 60%;
        padding-right: var(--spacing-30);
        margin-bottom: 0; }
        @media only screen and (min-width: 768px) {
          .news .news__col .news__text h2 {
            width: 100%;
            margin-bottom: 30px; } }
      .news .news__col .news__text .button {
        position: absolute;
        right: 0;
        bottom: 30px;
        z-index: 1; }
        @media only screen and (min-width: 768px) {
          .news .news__col .news__text .button {
            position: relative;
            bottom: 0; } }
      .news .news__col .news__text i {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 100%;
        opacity: .025;
        line-height: 0;
        font-style: normal;
        font-size: var(--font-s-i);
        font-family: var(--font-f-heading); }
    @media only screen and (min-width: 992px) {
      .news .news__col .news__image {
        width: 20%; }
      .news .news__col .news__text {
        float: unset;
        left: 30%; }
        .news .news__col .news__text h2 {
          width: 60%;
          margin: 0; }
        .news .news__col .news__text .button {
          position: absolute;
          right: 0;
          bottom: 30px;
          z-index: 1; }
      .news .news__col:nth-child(3n+1) .news__image {
        width: 50%; }
      .news .news__col:nth-child(3n+1) .news__text {
        float: right;
        padding: var(--spacing-60) 0;
        width: 40%;
        left: unset; }
        .news .news__col:nth-child(3n+1) .news__text h2 {
          margin-bottom: var(--spacing-30);
          font-size: var(--font-s-news);
          width: auto; }
        .news .news__col:nth-child(3n+1) .news__text .button {
          position: relative;
          bottom: 0; }
        .news .news__col:nth-child(3n+1) .news__text i {
          font-size: var(--font-s-i-l); }
      .news .news__col:nth-child(1) .news__image {
        right: 0;
        left: unset;
        width: 50%; }
      .news .news__col:nth-child(1) .news__text {
        left: 0;
        padding: var(--spacing-60) 0;
        width: 40%;
        float: unset; }
        .news .news__col:nth-child(1) .news__text h2 {
          margin-bottom: var(--spacing-30);
          font-size: var(--font-s-news);
          width: auto; }
        .news .news__col:nth-child(1) .news__text .button {
          position: relative;
          bottom: 0; }
        .news .news__col:nth-child(1) .news__text i {
          font-size: var(--font-s-i-l); } }
    @media only screen and (max-width: 479px) {
      .news .news__col .news__image {
        position: relative;
        width: 100%;
        min-height: 300px; }
      .news .news__col .news__text {
        width: 100%;
        left: unset;
        padding-bottom: 0; }
        .news .news__col .news__text h2 {
          width: 100%;
          margin-bottom: var(--spacing-30); }
        .news .news__col .news__text .button {
          position: relative;
          bottom: 0; }
      .news .news__col:nth-child(1) .news__image, .news .news__col:nth-child(1) .news__text, .news .news__col:nth-child(3n+1) .news__image, .news .news__col:nth-child(3n+1) .news__text {
        width: 100%; }
      .news .news__col:nth-child(1) .news__text, .news .news__col:nth-child(3n+1) .news__text {
        padding: var(--spacing-30) 0; }
        .news .news__col:nth-child(1) .news__text h2, .news .news__col:nth-child(3n+1) .news__text h2 {
          font-size: var(--font-s-heading-sm); } }
    .news .news__col.last-child {
      margin-bottom: 0; }

.single .container {
  max-width: 720px;
  padding: 0; }

.single .text {
  margin: 60px 0; }
  .single .text .container {
    padding: 0 var(--spacing-60); }
    @media only screen and (max-width: 479px) {
      .single .text .container {
        padding: 0 var(--spacing-30); } }

.single .quote blockquote {
  padding: 0; }
  @media only screen and (max-width: 479px) {
    .single .quote blockquote {
      padding: 0 var(--spacing-30); } }

.single .latest-news .container {
  max-width: 1440px;
  padding: 0 var(--spacing-120); }
  @media only screen and (max-width: 479px) {
    .single .latest-news .container {
      padding: 0 var(--spacing-30); } }

.quote {
  text-align: center;
  margin: var(--spacing-90) 0 var(--spacing-80) 0; }
  .quote blockquote {
    margin: 0;
    padding: 0 var(--spacing-180);
    font-family: var(--font-f-butler-m);
    font-size: var(--font-s-quote);
    line-height: 125%;
    max-width: 1200px;
    position: relative; }
    @media only screen and (max-width: 1024px) {
      .quote blockquote {
        padding: 0; } }
    .quote blockquote i {
      height: 25px;
      width: 25px;
      display: inline-block;
      position: relative; }
      .quote blockquote i:before {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 25px;
        height: 25px;
        font-size: 70px;
        position: absolute;
        content: open-quote;
        margin-left: -12.5px; }
    .quote blockquote span {
      display: block;
      padding-top: 30px;
      margin: 0 auto;
      font-size: var(--font-s-base);
      text-align: center;
      letter-spacing: 0.02em; }
      .quote blockquote span:before {
        content: "\2014";
        margin-right: 5px; }

.text {
  margin: var(--spacing-120) 0; }
  .text .container {
    padding: 0 var(--spacing-220); }
    @media only screen and (max-width: 1024px) {
      .text .container {
        padding: 0 var(--spacing-30); } }
  @media only screen and (max-width: 1024px) {
    .text {
      margin: var(--spacing-60) 0; } }
  .text .text__top {
    text-align: center;
    margin: 0 auto;
    position: relative; }
    .text .text__top .title__xl {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      opacity: .05; }
    .text .text__top h2 {
      padding: 0 var(--spacing-120); }
      @media only screen and (max-width: 479px) {
        .text .text__top h2 {
          padding: 0; } }
  .text .text__content.text__content--col--1 {
    max-width: 720px;
    margin: 0 auto; }
  .text .text__content.text__content--col--2 {
    display: flex; }
    .text .text__content.text__content--col--2 .text__content--left {
      margin-right: 30px;
      width: calc(50% - 30px); }
      @media only screen and (max-width: 479px) {
        .text .text__content.text__content--col--2 .text__content--left {
          margin: 0;
          width: 100%; } }
    .text .text__content.text__content--col--2 .text__content--right {
      margin-left: 30px;
      width: calc(50% - 30px); }
      @media only screen and (max-width: 479px) {
        .text .text__content.text__content--col--2 .text__content--right {
          margin: 0;
          width: 100%; } }
  @media only screen and (max-width: 479px) {
    .text .text__content {
      flex-flow: row;
      flex-wrap: wrap; }
      .text .text__content p {
        flex: 0 0 100%; } }

[data-animate="text"] .js-animate {
  opacity: 0;
  transform: translateY(75px);
  will-change: transform;
  backface-visibility: hidden; }

[data-animate="text"] .button {
  opacity: 0;
  transform: translateY(75px);
  will-change: transform;
  backface-visibility: hidden; }

[data-animate="text"] .title__xl.vertical {
  opacity: 0;
  transform: translateX(-75px) rotate(90deg); }

[data-animate="text"].text-image__image--dark .title__xl.vertical {
  opacity: 0; }

[data-animate="text"].text-image__image--right .title__xl.vertical {
  opacity: 0;
  transform: translateX(75px) rotate(-90deg); }

.text-image {
  margin: var(--spacing-100) 0;
  display: flex;
  position: relative; }
  @media only screen and (max-width: 1024px) {
    .text-image {
      margin: 0; } }
  @media only screen and (max-width: 479px) {
    .text-image {
      flex-flow: row;
      flex-wrap: wrap;
      margin: 0; }
      .text-image .text-image__col {
        flex: 0 0 100%; } }
  .text-image .title__xl {
    top: 380px;
    right: 0;
    position: absolute;
    opacity: 0.05;
    transform: rotate(270deg);
    margin: 0; }
    @media only screen and (max-width: 479px) {
      .text-image .title__xl {
        top: 600px;
        right: -20px; } }
  .text-image.text-image__image--right .title__xl {
    right: unset;
    left: -30px;
    transform: rotate(-270deg); }
    @media only screen and (max-width: 479px) {
      .text-image.text-image__image--right .title__xl {
        top: 600px;
        right: -30px;
        left: unset;
        transform: rotate(270deg); } }
  .text-image.text-image__image--right .text-image__image {
    left: unset;
    right: 0; }
    .text-image.text-image__image--right .text-image__image .image__source {
      right: 0;
      left: 120px; }
      @media only screen and (max-width: 1024px) {
        .text-image.text-image__image--right .text-image__image .image__source {
          left: 60px; } }
      @media only screen and (max-width: 479px) {
        .text-image.text-image__image--right .text-image__image .image__source {
          left: 0; } }
  .text-image.text-image__image--right .text-image__content {
    padding: var(--spacing-120) 0 var(--spacing-120) var(--spacing-180);
    float: left; }
    @media only screen and (max-width: 1024px) {
      .text-image.text-image__image--right .text-image__content {
        padding: var(--spacing-120) 0; } }
    @media only screen and (max-width: 479px) {
      .text-image.text-image__image--right .text-image__content {
        padding: var(--spacing-30) 0; } }
  .text-image .text-image__image {
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    min-height: 500px;
    position: absolute;
    overflow: hidden; }
    @media only screen and (max-width: 479px) {
      .text-image .text-image__image {
        position: unset;
        width: 100%; } }
    .text-image .text-image__image .image__source {
      top: 0;
      left: 0;
      right: 120px;
      height: 100%;
      position: absolute;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
      @media only screen and (max-width: 1024px) {
        .text-image .text-image__image .image__source {
          right: 60px; } }
      @media only screen and (max-width: 479px) {
        .text-image .text-image__image .image__source {
          max-height: 500px;
          right: 0; } }
  .text-image .text-image__content {
    width: 50%;
    padding: var(--spacing-120) var(--spacing-180) var(--spacing-120) 0;
    float: right; }
    .text-image .text-image__content .title {
      margin-bottom: 30px; }
    .text-image .text-image__content p:first-of-type:first-letter {
      float: left;
      font-size: 6.5em;
      line-height: -40%;
      font-family: var(--font-f-heading);
      padding: 40px 30px 30px 0; }
    @media only screen and (max-width: 1024px) {
      .text-image .text-image__content {
        padding: var(--spacing-120) 0; } }
    @media only screen and (max-width: 479px) {
      .text-image .text-image__content {
        width: 100%;
        padding: var(--spacing-30) 0; } }
  .text-image.text-image__image--dark {
    margin: 0;
    padding: var(--spacing-120) 0;
    background-color: var(--color-black);
    color: var(--color-white); }
    .text-image.text-image__image--dark .title__xl {
      opacity: 0.3;
      color: white;
      top: 500px; }
    .text-image.text-image__image--dark .button {
      border-color: var(--color-white);
      color: var(--color-white); }
      .text-image.text-image__image--dark .button::before {
        background-color: var(--color-white); }
    @media only screen and (max-width: 479px) {
      .text-image.text-image__image--dark {
        padding: 0; } }

.timeline {
  padding: var(--spacing-120) 0;
  background: var(--color-black);
  color: var(--color-white); }
  .timeline .timeline__top {
    text-align: center;
    max-width: 600px;
    margin: 0 auto; }
    .timeline .timeline__top .title__xl {
      position: absolute;
      top: 55px;
      left: 50%;
      transform: translateX(-50%);
      color: var(--color-white);
      opacity: .15; }
  .timeline .timeline__content {
    display: flex;
    justify-content: space-between;
    margin-top: var(--spacing-120);
    padding: 0 var(--spacing-60); }
    @media only screen and (max-width: 1024px) {
      .timeline .timeline__content {
        padding: 0; } }
    @media only screen and (max-width: 479px) {
      .timeline .timeline__content {
        flex-flow: row;
        flex-wrap: wrap;
        margin-top: var(--spacing-60);
        padding: 0; } }
    .timeline .timeline__content .timeline__con {
      flex: 0 1; }
      @media only screen and (max-width: 479px) {
        .timeline .timeline__content .timeline__con {
          width: 100%;
          flex: 0 0 100%; } }
      .timeline .timeline__content .timeline__con h3 {
        font-size: var(--font-s-heading-l);
        margin-bottom: 30px; }
        @media only screen and (max-width: 1024px) {
          .timeline .timeline__content .timeline__con h3 {
            margin: 0; } }
      .timeline .timeline__content .timeline__con h2 {
        font-size: var(--font-s-heading-sm);
        padding: var(--spacing-30) var(--spacing-60);
        margin: 0; }
        @media only screen and (max-width: 1024px) {
          .timeline .timeline__content .timeline__con h2 {
            padding: var(--spacing-30) 0; } }
        @media only screen and (max-width: 479px) {
          .timeline .timeline__content .timeline__con h2 {
            padding: var(--spacing-30) 0; } }
      .timeline .timeline__content .timeline__con p {
        opacity: .6; }

/* styles */
input.button {
  background: none; }

.button {
  width: auto;
  height: 50px;
  cursor: pointer;
  padding: 0 45px 0 25px;
  font-weight: var(--font-w-medium);
  font-size: var(--font-s-button);
  line-height: var(--lh-button);
  letter-spacing: var(--spacing-2);
  text-transform: uppercase;
  color: var(--color-black);
  position: relative;
  display: inline-block;
  border: 1px solid var(--color-black); }
  .button::before {
    content: '';
    top: 50%;
    right: 20px;
    position: absolute;
    transform: translateY(-50%);
    z-index: 2;
    width: 7px;
    height: 13px;
    -webkit-mask: url(..//img/icon-arrow.svg);
    background-color: var(--color-black); }
  .button:hover {
    background-color: var(--color-black);
    color: var(--color-white);
    transition: all cubic-bezier(0.77, 0, 0.175, 1) 200ms; }
    .button:hover::before {
      background-color: var(--color-white); }

.button--light {
  border-color: var(--color-white);
  color: var(--color-white); }
  .button--light::before {
    background-color: var(--color-white); }
  .button--light:hover {
    background: var(--color-white);
    color: var(--color-black); }
    .button--light:hover::before {
      background-color: var(--color-black); }

.header--dark .button {
  border-color: var(--color-white);
  color: var(--color-white); }
  .header--dark .button::before {
    background-color: var(--color-white); }
  .header--dark .button:hover {
    background: var(--color-white);
    color: var(--color-black); }
    .header--dark .button:hover::before {
      background-color: var(--color-black); }
